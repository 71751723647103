import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";

import Icon from "@/components/icon/Icon";
import { useKeyPress } from "@/hooks/useKeyPress";

import DesktopNavItem from "./DesktopNavItem";

const DesktopNavMenu = ({ menu, name, isChild = false }) => {
  const router = useRouter();
  const [isHovered, setIsHovered] = useState(false); // Hover state for this popover
  const [parentElement, setParentElement] = useState(null);
  const popoverParentId = `desktop-nav-menu-${name}`;

  const namesMap = {
    "Soccer Odds": "Soccer",
    "Soccer Picks": "Soccer",
    "Soccer News": "Soccer",
  };

  const formattedName = namesMap[name] ?? name;

  // useEffect only runs client side, ensuring the document
  // exists before getting the popover parent element
  useEffect(() => {
    const popoverParentElement = document.getElementById(popoverParentId);
    if (popoverParentElement) {
      setParentElement(popoverParentElement);
    }
  }, [popoverParentId]);

  // If popover is open, close on escape
  useKeyPress("Escape", isHovered, () => setIsHovered(false));

  // If the event that triggered onBlur is not a link, we can
  // assume that the popover has lost focus and should be closed
  const onPopoverBlur = useCallback(
    (event) => {
      if (isHovered && event?.relatedTarget?.tagName !== "A") {
        setIsHovered(false);
      }
    },
    [isHovered]
  );

  const handlePopoverMouseEnter = () => {
    setIsHovered(true);
  };

  const handlePopoverMouseLeave = () => {
    setIsHovered(false);
  };

  const handleOnClick = (path) => {
    if (path) {
      router.push(path);
      setIsHovered(false);
    }
  };

  return (
    <div
      id={popoverParentId}
      onMouseEnter={handlePopoverMouseEnter}
      onMouseLeave={handlePopoverMouseLeave}
      className="popover-parent"
    >
      {parentElement && (
        <Popover
          isOpen={true}
          onClickOutside={() => setIsHovered(false)}
          parentElement={parentElement}
          containerStyle={{ width: isChild ? "284px" : "" }}
          positions={[isChild ? "right" : "bottom"]}
          align="start"
          content={
            <div
              className={`z-[2] grid w-full min-w-[10rem] grid-cols-1 border border-border-blue bg-white py-2 ${
                isChild ? "relative bottom-2 w-max rounded-r-lg" : "rounded-lg"
              }`}
              onBlur={onPopoverBlur}
              style={{ display: isHovered ? "block" : "none" }}
            >
              {!isChild && (
                <div className="flex flex-row items-center px-4 pb-4 pt-2 ">
                  <Icon
                    color={"currentColor"}
                    className="mr-2 text-blue"
                    height={"20px"}
                    name={`nav${name}`?.replace(/ /g, "") || ""}
                    width={"20px"}
                  />
                  <p className="font-heading text-xs font-bold uppercase tracking-wide text-dark">
                    {menu.menuTitle === "Horse Racing"
                      ? "Today's Picks"
                      : menu.menuTitle}
                  </p>
                </div>
              )}
              <div className="grid grid-cols-1 divide-y divide-border-grey">
                {menu?.menuOptions &&
                  Object.entries(menu.menuOptions).map(([key, item]) => (
                    <DesktopNavItem
                      key={key}
                      item={item}
                      name={key}
                      isChild={true}
                      setIsHovered={setIsHovered}
                    />
                  ))}
              </div>
            </div>
          }
        >
          <button
            key={formattedName}
            aria-label={formattedName}
            onClick={() => handleOnClick(menu?.path)}
            className={`relative flex items-center whitespace-nowrap px-3 py-2 text-[0.925rem] tracking-wide xl:text-base ${
              isChild
                ? `${!menu?.league ? "text-black text-left" : "flex gap-2 px-5 font-heading text-nav-blue"} ${!menu?.parent ? "text-black text-left" : "flex gap-2 px-5 font-heading text-nav-blue"} nav-bar-caret-child flex w-full items-center`
                : "nav-bar-caret mx-2 font-semibold text-white"
            } ${
              isHovered
                ? !isChild
                  ? "nav-bar-caret-open text-[#ffffffbf]"
                  : "nav-bar-caret-open-child"
                : ""
            }`}
          >
            {isChild && menu?.league && (
              <Icon
                className="h-[20px] w-[25px]"
                color={"currentColor"}
                height={"20px"}
                name={`${formattedName.toLowerCase().replace(/ /g, "")}Category`}
                width={"20px"}
              />
            )}
            {formattedName}
          </button>
        </Popover>
      )}
    </div>
  );
};

export default DesktopNavMenu;
